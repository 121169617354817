export const DEF_ITEM = {
  position_id: null,
  mandatory: false,
  has_condition: false,
  condition_name: '',
  condition_operator: 'greater_than',
  condition_value: 0,
  condition_value_2: 0,
}

export const UNSAVED_TEXT = 'You have unsaved changes. <br /> Are you sure you want to cancel them?'
