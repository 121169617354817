<template>
  <div class="TagApproval">
    <svg class="delete" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('delete')">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00001 1.33301C4.31334 1.33301 1.33334 4.31301 1.33334 7.99967C1.33334 11.6863 4.31334 14.6663 8.00001 14.6663C11.6867 14.6663 14.6667 11.6863 14.6667 7.99967C14.6667 4.31301 11.6867 1.33301 8.00001 1.33301ZM11.3333 10.393L10.3933 11.333L8.00001 8.93967L5.60668 11.333L4.66668 10.393L7.06001 7.99967L4.66668 5.60634L5.60668 4.66634L8.00001 7.05967L10.3933 4.66634L11.3333 5.60634L8.94001 7.99967L11.3333 10.393Z" fill="white"/>
    </svg>
    <div class="info">{{ condition.condition_name }} {{ (condition.condition_value/1000).toFixed(2) }}k -</div>
    <div class="edit" @click="$emit('edit', index, condition)">Edit</div>
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    condition: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['delete', 'edit'],
  methods: {},
}
</script>

<style lang="scss" scoped>
.TagApproval {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  //width: 220px;
  height: 28px;
  background: #0895d1;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;

  .close {
    border-radius: 50%;
    background: #ffffff;
    height: 13.5px;
    width: 13.5px;
  }

  .info {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .edit {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-decoration: underline;
    display: flex;
    align-items: center;
    color: #ffffff;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .delete,
  .edit {
    cursor: pointer;
  }
}
</style>
